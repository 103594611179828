import profiles from "../../_data/profiles.json";
// import { slugify } from "../lib/slug";

export default function result() {
  return {
    personalProfile: null,
    personalTip: null,

    init() {
      const params = new URLSearchParams(document.location.search);
      if (params.has("s")) {
        const score = +params.get("s");
        const profile = profiles.find(
          (p) => score >= p.score[0] && score <= p.score[1]
        );
        this.personalProfile = profile?.key;
      }
      if (params.has("t")) {
        this.personalTip = +params.get("t");
      }
    },

    get documentLanguage() {
      return document.firstElementChild.getAttribute("lang");
    },

    get shareURL() {
      return `${window.location.origin}/share`;
    },

    get facebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.shareURL}`;
    },

    get linkedinUrl() {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        this.shareURL
      )}`;
    },

    get twitterUrl() {
      const text = `${this.shareURL}`;
      return `http://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
    },

    get whatsappUrl() {
      const text = this.shareURL;
      return `whatsapp://send?text=${encodeURIComponent(text)}`;
    },

    async shareThroughNavigator(event) {
      if (navigator.canShare && navigator.canShare({ url: this.shareURL })) {
        event.preventDefault();
        await navigator.share({
          url: this.shareURL,
        });
      }
    },
  };
}
